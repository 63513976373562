<template>
  <div>
    <img id="image" src="117.png" style="display: none" />
    <model-viewer id="viewer" :src="fileName" poster="" shadow-intensity="1" ar ar-modes="scene-viewer webxr quick-look"
      alt="A 3D model carousel">


      <button slot="ar-button" id="ar-button">
        <div class="ar-button-inner">
          <svg version="1.1" id="view_x5F_in_x5F_AR_x5F_icon" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="36px" height="36px" viewBox="0 0 36 36"
            enable-background="new 0 0 24 24" xml:space="preserve">
            <rect id="Bounding_Box" x="0" y="0" fill="none" width="36" height="36"></rect>
            <g id="Art_layer">
              <path d="M3,4c0-0.55,0.45-1,1-1h2V1H4C2.35,1,1,2.35,1,4v2h2V4z"></path>
              <path d="M20,3c0.55,0,1,0.45,1,1v2h2V4c0-1.65-1.35-3-3-3h-2v2H20z"></path>
              <path d="M4,21c-0.55,0-1-0.45-1-1v-2H1v2c0,1.65,1.35,3,3,3h2v-2H4z"></path>
              <path d="M20,21c0.55,0,1-0.45,1-1v-2h2v2c0,1.65-1.35,3-3,3h-2v-2H20z"></path>
              <g>
                <path d="M18.25,7.6l-5.5-3.18c-0.46-0.27-1.04-0.27-1.5,0L5.75,7.6C5.29,7.87,5,8.36,5,8.9v6.35c0,0.54,0.29,1.03,0.75,1.3
			l5.5,3.18c0.46,0.27,1.04,0.27,1.5,0l5.5-3.18c0.46-0.27,0.75-0.76,0.75-1.3V8.9C19,8.36,18.71,7.87,18.25,7.6z M7,14.96v-4.62
			l4,2.32v4.61L7,14.96z M12,10.93L8,8.61l4-2.31l4,2.31L12,10.93z M13,17.27v-4.61l4-2.32v4.62L13,17.27z"></path>
              </g>
            </g>
          </svg>
        </div>
      </button>


      <div id="ar-prompt">

      </div>

      <button id="ar-failure">
        AR is not tracking!
      </button>

    </model-viewer>
  </div>
</template>

<script>
  import ModelViewer from '@google/model-viewer';
  export default {
    name: 'HelloWorld',
    components: {
      ModelViewer
    },
    data: function () {
      return {
        fileName: String
      }
    },
    props: {
      msg: String
    },
    mounted: function () {

      var NODE = "https://us-central1-viewpixel.cloudfunctions.net/";
      //NODE = "http://localhost:5001/viewpixel/us-central1/";

      var file = this.$route.query.file;
      //var proxy = this.$route.query.proxy;
      var width = this.$route.query.width || 100;
      var height = this.$route.query.height || 100;

      if (file == undefined) {
        this.$data.fileName = "Table.glb";
        return;
      }
      if (file.includes(".png") || file.includes(".jpg")) {
        var viewer = document.getElementById('viewer');
        viewer.setAttribute("ar-placement", "wall");
        this.$data.fileName = NODE + "gltf?width=" + width + "&height=" +
          height + "&image=" + file;
      } else {

        //this.$data.fileName = NODE + "proxy?url=" + window.btoa(file + "&download=1"); // todo: take from original query???

        this.$data.fileName = file;

      }

    }
  }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /* This keeps child nodes hidden while the element loads */
  :not(:defined)>* {
    display: none;
  }

  model-viewer {
    background-color: #eee;
    overflow-x: hidden;
    --poster-color: #eee;
    height: 100vh;
    width: 100vw;
    position: fixed;
  }



  #ar-button {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent;
    white-space: nowrap;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    color: #4285f4;
    height: 100vh;
    width: 100vw;
    border: none;
  }

  .ar-button-inner {
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 18px);
    background-color: #fff;
    border-radius: 18px;
    height: 36px;
    width: 36px;
    border: 1px solid #DADCE0;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
  }

  .ar-button-inner svg {
    position: relative;
    top: 6px;
    left: 6px;
  }

  #ar-button:active {
    background-color: #E8EAED;
  }

  #ar-button:focus {
    outline: none;
  }

  #ar-button:focus-visible {
    outline: 1px solid #4285f4;
  }

  @keyframes circle {
    from {
      transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
    }

    to {
      transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
    }
  }

  @keyframes elongate {
    from {
      transform: translateX(100px);
    }

    to {
      transform: translateX(-100px);
    }
  }



  model-viewer>#ar-prompt {
    position: absolute;
    left: 50%;
    bottom: 175px;
    animation: elongate 2s infinite ease-in-out alternate;
    display: none;
  }

  model-viewer[ar-status="session-started"]>#ar-prompt {
    display: block;
  }

  model-viewer>#ar-prompt>img {
    animation: circle 4s linear infinite;
  }

  model-viewer>#ar-failure {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 175px;
    display: none;
  }

  model-viewer[ar-tracking="not-tracking"]>#ar-failure {
    display: block;
  }

  .slider {
    width: 100%;
    text-align: center;
    overflow: hidden;
    position: absolute;
    bottom: 16px;
  }

  .slides {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .slide {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 10px;
    border: none;
    display: flex;
  }

  .slide.selected {
    border: 2px solid #4285f4;
  }

  .slide:focus {
    outline: none;
  }

  .slide:focus-visible {
    outline: 1px solid #4285f4;
  }
</style>