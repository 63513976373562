import Vue from 'vue'
import App from './App.vue'

import VueRouter from "vue-router";

const routes = [{ path: "/hello", component: App }];
Vue.config.productionTip = false;
Vue.use(VueRouter);
const router = new VueRouter({
  routes
});
new Vue({
  render: (h) => h(App),
  router
}).$mount("#app");
